<script setup>
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { useAuthStore } from '~/stores/auth';
import { useCenterStore } from '~/stores/centerDetails';
import { useMessageStore } from '~/stores/messages';
import { trackPageLoad } from '~/composables/useTracking';

// Center store
const { centerUid, centerName } = storeToRefs(useCenterStore());

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Prepare form
const { isSubmitting, handleSubmit } = useForm();

// Auth
const authStore = useAuthStore();
const { handleUserData } = authStore;
const { statusObj } = storeToRefs(authStore);

const isFormSubmitting = computed(() => isSubmitting.value || statusObj.value?.success);

const getFormClass = computed(() => {
    return {
        'is-submitting': isFormSubmitting.value
    }
})

const buttonConfig = ref({
    fieldType: 'submit',
    label: staticKeys.value?.account_form_delete_button_label,
    active: true,
    visible: true,
    buttonType: 'primary',
    isPending: isFormSubmitting,
    large: true
});

const onSubmit = handleSubmit(async () => {
    await handleUserData({
            method: 'DELETE',
            scrollDiv: document.querySelector('.sidebar-content-inner'),
            trackingPayload: {
                deleteAccount: {
                    center_id: centerUid.value,
                    center_name: centerName.value
                }
            }
        }
    )
});

trackPageLoad('Account_Delete', {
    center_id: centerUid.value,
    center_name: centerName.value
});
</script>

<template>
    <SideBarWrapper>
        <div class="delete-account-content">
            <div class="delete-account-intro">
                <FormMessage :status-obj="statusObj" />

                <SideBarMainHeadline :text="staticKeys?.menu_deleteaccount_markdown" />

                <p>{{ staticKeys?.account_form_delete_intro_1 }}</p>
                <p>{{ staticKeys?.account_form_delete_intro_2 }}</p>
                <p>{{ staticKeys?.account_form_delete_intro_3 }}</p>
            </div>
            <form class="delete-account-form" :class="getFormClass" @submit.prevent="onSubmit">
                <p>
                    {{ staticKeys?.account_form_delete_intro_4 }}
                </p>

                <FormButton :config="buttonConfig" />
            </form>
        </div>
    </SideBarWrapper>
</template>

<style lang="scss">
.delete-account-content {
    display: flex;
    width: 100%;
    flex-flow: column;
    min-height: calc(100vh - 186px);
    text-align: center;
    position: relative;
    justify-content: space-between;

    @include bp(medium1) {
        min-height: calc(100vh - 160px);
    }

    p {
        @include body2-medium;
    }
}

.delete-account-form {
    &::after {
        content: '';
        transition: opacity 400ms ease;
        opacity: 0;
    }

    &.is-submitting {
        @include form-is-submitting;
    }
}
</style>
